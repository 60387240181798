.tag {
    opacity: 1;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: scale(1);
}

.tag:hover {
    opacity: 0.9;
    transform: scale(1.05);
}

.tag.active {
    opacity: 1;
}

.text-title {
    font-size: 70px;
    font-weight: 700;
}